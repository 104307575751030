<template>
  <a-modal title="转办" :width="600" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="当前节点" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        {{ this.recordData.name }}
      </a-form-item>
      <a-form-item label="转办用户" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-select mode="multiple" style="width: 100%" placeholder="请选择用户" @change="handleChangeUser">
          <a-select-option v-for="(item, index) in userList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="转办角色" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-select mode="multiple" style="width: 100%" placeholder="请选择角色" @change="handleChangeRole">
          <a-select-option v-for="(item, index) in roleList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="转办原因" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-textarea
          :rows="4"
          placeholder="请输入审批意见"
          v-decorator="['comment', { rules: [{ required: true, message: '请输入转办原因！' }] }]"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as Request from '@/api/starter';

export default {
  name: 'turn',
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      tableLoading: false,
      recordData: {},
      userList: [],
      roleList: [],
      userChangList: [],
      roleChangList: [],
      form: this.$form.createForm(this)
    };
  },
  methods: {
    /**
     * 打开提交窗口
     *
     * @author fengshuonan
     * @date 2021/7/22 21:14
     */
    open(record) {
      this.recordData = record;
      this.visible = true;

      // 获取角色列表
      this.getRoleList();

      // 获取用户列表
      this.getUserList();
    },

    /**
     * 获取角色列表
     *
     * @author fengshuonan
     * @date 2021/8/3 21:20
     */
    getRoleList() {
      Request.roleSelector().then(res => {
        this.roleList = res.data;
      });
    },

    /**
     * 获取用户列表
     *
     * @author fengshuonan
     * @date 2021/8/3 21:20
     */
    getUserList() {
      Request.userSelector().then(res => {
        this.userList = res.data;
      });
    },

    /**
     * 选择用户时的监听
     *
     * @author fengshuonan
     * @date 2021/8/3 21:20
     */
    handleChangeUser(value) {
      this.userChangList = value;
    },

    /**
     * 选择角色时
     *
     * @author fengshuonan
     * @date 2021/8/3 21:21
     */
    handleChangeRole(value) {
      this.roleChangList = value;
    },

    /**
     * 提交转办
     *
     * @author fengshuonan
     * @date 2021/7/22 21:15
     */
    handleSubmit() {
      let formData = {};

      formData.taskId = this.recordData.taskId;

      // 退回的角色和用户id的集合，共同集合
      formData.assigneeList = [];
      if (this.userChangList.length > 0) {
        this.userChangList.forEach(item => {
          formData.assigneeList.push(item);
        });
      }
      if (this.roleChangList.length > 0) {
        this.roleChangList.forEach(item => {
          formData.assigneeList.push(item);
        });
      }
      // 如果没有选择角色和用户，提示请选择
      if (formData.assigneeList.length < 1) {
        this.$message.warning('请选择转办用户或角色');
        return;
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          // 设置审批意见
          formData.comment = values.comment;

          // 更改按钮加载中
          this.confirmLoading = true;

          // 提交转办用户
          Request.turnForm(formData)
            .then(() => {
              this.confirmLoading = false;

              // 更改按钮加载中
              this.$message.success('转办成功');
              this.visible = false;
              window.parent.postMessage({ action: 'close' }, '*');
            })
            .catch(() => {
              this.confirmLoading = false;
              this.$message.error('审批失败');
            });
        }
      });
    },

    /**
     * 关闭
     *
     * @author fengshuonan
     * @date 2021/7/22 21:16
     */
    handleCancel() {
      this.visible = false;
    }
  }
};
</script>