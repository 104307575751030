<template>
  <a-modal title="提交" :width="600" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="当前节点" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        {{ this.recordData.name }}
      </a-form-item>
      <a-form-item label="退回环节" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-table
          size="middle"
          :columns="columns"
          :pagination="false"
          :dataSource="loadData"
          :rowKey="record => record.keys"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: 'radio' }"
        />
      </a-form-item>
      <a-form-item label="退回意见" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-textarea
          :rows="4"
          placeholder="请输入退回意见"
          v-decorator="['comment', { rules: [{ required: true, message: '请输入退回意见' }] }]"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as Request from '@/api/starter';

export default {
  name: 'submit',
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      columns: [
        {
          title: '环节名称',
          dataIndex: 'name'
        },
        {
          title: '操作人',
          dataIndex: 'assigneeName'
        }
      ],
      visible: false,
      confirmLoading: false,
      tableLoading: false,
      recordData: {},
      form: this.$form.createForm(this),
      loadData: [],
      selectedRows: [],
      selectedRowKeys: []
    };
  },
  methods: {
    /**
     * 打开提交窗口
     *
     * @author fengshuonan
     * @date 2021/7/22 21:14
     */
    open(record, buttonList, formDataReq) {
      this.formDataReq = formDataReq;
      this.buttonList = buttonList;
      this.recordData = record;
      this.visible = true;
      this.flowableInstanceHisUserTasks();
    },

    /**
     * 获取节点列表
     *
     * @author fengshuonan
     * @date 2021/7/22 23:45
     */
    flowableInstanceHisUserTasks() {
      Request.flowableInstanceHisUserTasks({ id: this.recordData.procInsId }).then(res => {
        if (res.data.length > 0) {
          let index = 1;
          res.data.forEach(item => {
            item.keys = index++;
            this.loadData.push(item);
          });
        }
      });
    },

    /**
     * 提交待办任务
     *
     * @author fengshuonan
     * @date 2021/7/22 21:15
     */
    handleSubmit() {
      let formData = {};

      formData.taskId = this.recordData.taskId;

      // 判断有没有选择节点
      if (!this.selectedRows || this.selectedRows.length === 0) {
        this.$message.error('请选择退回节点');
        return;
      }

      formData.targetActId = this.selectedRows[0].id;
      formData.targetActName = this.loadData.filter(item => item.keys === this.selectedRowKeys[0])[0].name;

      this.form.validateFields((err, values) => {
        if (!err) {
          // 设置审批意见
          formData.comment = values.comment;

          // 更改按钮加载中
          this.confirmLoading = true;

          // 获取表单的内容数据
          Request.backForm(formData)
            .then(() => {
              this.confirmLoading = false;

              // 更改按钮加载中
              this.$message.success('退回成功');
              this.visible = false;
              window.parent.postMessage({ action: 'close' }, '*');
            })
            .catch(() => {
              this.confirmLoading = false;

              this.$message.error('退回失败');
            });
        }
      });
    },

    /**
     * 关闭
     *
     * @author fengshuonan
     * @date 2021/7/22 21:16
     */
    handleCancel() {
      this.visible = false;
    },

    /**
     * 节点列表选择
     *
     * @author fengshuonan
     * @date 2021/8/3 23:28
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    }
  }
};
</script>