<template>
  <a-modal title="跳转" :width="600" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="当前节点" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        {{ this.recordData.name }}
      </a-form-item>
      <a-form-item label="跳转节点" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-select
          style="width: 100%"
          placeholder="请选择跳转节点"
          @change="handleChangeNode"
          v-decorator="['targetActId', { rules: [{ required: true, message: '请输入跳转原因！' }] }]"
        >
          <a-select-option v-for="(item, index) in jumpNodeList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="跳转原因" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-textarea
          :rows="4"
          placeholder="请输入跳转原因"
          v-decorator="['comment', { rules: [{ required: true, message: '请输入跳转原因！' }] }]"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as Request from '@/api/starter';

export default {
  name: 'jump',
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      tableLoading: false,
      recordData: {},
      jumpNodeList: [],
      form: this.$form.createForm(this)
    };
  },
  methods: {
    /**
     * 打开提交窗口
     *
     * @author fengshuonan
     * @date 2021/7/22 21:14
     */
    open(record) {
      this.recordData = record;
      this.visible = true;

      // 获取跳转节点列表
      this.getJumpNodeList();
    },

    /**
     * 获取跳转节点列表
     *
     * @author fengshuonan
     * @date 2021/8/3 21:20
     */
    getJumpNodeList() {
      Request.jumpNodeSelector({ id: this.recordData.processDefinitionId }).then(res => {
        this.jumpNodeList = res.data;
      });
    },

    /**
     * 选择节点的监听
     *
     * @author fengshuonan
     * @date 2021/8/3 21:20
     */
    handleChangeNode(value) {
      // 设置name
      for (const item of this.jumpNodeList) {
        if (item?.id === value) {
          this.jumpNodeSelectName = item.name;
        }
      }
    },

    /**
     * 提交跳转
     *
     * @author fengshuonan
     * @date 2021/7/22 21:15
     */
    handleSubmit() {
      let formData = {};

      formData.taskId = this.recordData.taskId;
      formData.targetActName = this.jumpNodeSelectName;

      this.form.validateFields((err, values) => {
        if (!err) {
          // 设置审批意见和委托人
          formData.comment = values.comment;
          formData.targetActId = values.targetActId;

          // 更改按钮加载中
          this.confirmLoading = true;

          // 提交跳转
          Request.jumpForm(formData)
            .then(() => {
              this.confirmLoading = false;

              // 更改按钮加载中
              this.$message.success('跳转成功');
              this.visible = false;
              window.parent.postMessage({ action: 'close' }, '*');
            })
            .catch(() => {
              this.confirmLoading = false;
              this.$message.error('审批失败');
            });
        }
      });
    },

    /**
     * 关闭
     *
     * @author fengshuonan
     * @date 2021/7/22 21:16
     */
    handleCancel() {
      this.visible = false;
    }
  }
};
</script>